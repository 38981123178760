<template>
    <layout :menu-tree="menuTree"></layout>
</template>

<script>
import Layout from "@/views/front/jsf35/operations";
import { reactive, toRefs } from "@vue/composition-api";

export default {
    name: "EngineeringCenter",
    components: {
        Layout,
    },
    setup() {
        const event = reactive({
            //默认加载的组件

            menuTree: [
                /* {
                    title: "运行概况",
                    component_key: "operation-situation",
                }, */
                {
                    title: "标准计划管理",
                    children: [
                        {
                            title: "运营SOP",
                            children: [
                                {
                                    title: "标准列表",
                                    iframe: "device.360sheji.cn/ems/sop/index",
                                },
                                {
                                    title: "标准分类",
                                    iframe: "device.360sheji.cn/ems/sopCategory/index",
                                },
                                {
                                    title: "检测方法",
                                    iframe: "device.360sheji.cn/ems/sopCheckWay/index",
                                },
                            ],
                        },
                        {
                            title: "工作计划",
                            children: [
                                {
                                    title: "计划列表",
                                    iframe: "device.360sheji.cn/ems/plan/index",
                                },
                                {
                                    title: "计划分类",
                                    iframe: "device.360sheji.cn/ems/planCategory/index",
                                },
                            ],
                        },
                        {
                            title: "路线管理",
                            iframe: "device.360sheji.cn/ems/equCheckRoute/index",
                        },
                    ],
                },
                {
                    title: "工程巡检管理",
                    children: [
                        {
                            title: "巡检任务",
                            iframe: "device.360sheji.cn/ems/checkTask/index",
                        },
                        {
                            title: "巡检类型",
                            iframe: "device.360sheji.cn/ems/checkCategory/index",
                        },
                        {
                            title: "装修管理",
                            iframe: "device.360sheji.cn/ems/renovation/index",
                        },
                        {
                            title: "保养任务",
                            iframe: "device.360sheji.cn/ems/maintenanceTask/index",
                        },
                        {
                            title: "能源抄表",
                            children: [
                                {
                                    title: "表具管理",
                                    iframe: "device.360sheji.cn/ems/energyMeter/index",
                                },
                                {
                                    title: "抄表记录",
                                    iframe: "device.360sheji.cn/ems/energy/index",
                                },
                                {
                                    title: "任务管理",
                                    iframe: "device.360sheji.cn/ems/energyMeterTask/index",
                                },
                            ],
                        },
                        /* {
                title: "保洁清扫",
                iframe: "",
            },
            {
                title: "绿化养护",
                iframe: "",
            },

            {
                title: "品质核查",
                iframe: "",
            },
            {
                title: "安保巡视",
                iframe: "",
            }, */
                    ],
                },
                {
                    title: "设备设施管理",
                    children: [
                        {
                            title: "设备列表",
                            iframe: "device.360sheji.cn/ems/equipment/index",
                        },
                        {
                            title: "设施列表",
                            iframe: "device.360sheji.cn/ems/facility/index",
                        },
                        
                    ],
                },
                {
                    title: "报警信息管理",

                    children: [
                        {
                            title: "设备报警列表",
                            iframe: "device.360sheji.cn/ems/alarmRecord/index",
                        },
                        {
                            title: "消防报警列表",
                            iframe: "device.360sheji.cn/ems/fireAlarmHistoryLog/index",
                        },
                        {
                            title: "智能诊断",
                            component_key: "intelligent-diagnosis",
                        },
                        {
                            title: "综合报警",
                            component_key: "alarm-information",
                        },
                    ],
                },
                {
                    title: "系统网络拓扑",
                    component_key: "system-network-topology",
                },
                /* {
            title: "运行模式管理",
            component_key: "operation-mode",
        }, */
            ],
            // 设备列表
            fields: [
                { key: "index", label: "ID" },
                { key: "plant_no", label: "机组编号" },
                { key: "DDC", label: "DDC" },
                { key: "area", label: "区域" },
                { key: "floor", label: "楼层" },
                { key: "control", label: "启停控制" },
                { key: "run_status", label: "运行状态" },
                { key: "failure_warning", label: "故障报警" },
                { key: "manual_automatic", label: "手自动状态" },
                { key: "antifreeze_alarm", label: "防冻报警" },
                { key: "setting_temperature", label: "设定温度" },
                { key: "setting_humidity", label: "设定湿度" },
                { key: "supply_air_temperature", label: "送风温度" },
                { key: "blasting_humidity", label: "送风湿度" },
                { key: "action", label: "操作" },
            ],
            items: [
                {
                    plant_no: "AHU-F2-2-a",
                    DDC: "DDC-F2-3",
                    area: "南楼",
                    floor: "F2",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F2-2-b",
                    DDC: "DDC-F2-5",
                    area: "南楼",
                    floor: "F2",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F5-2",
                    DDC: "DDC-F5-3",
                    area: "南楼",
                    floor: "F5",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F7-2",
                    DDC: "DDC-F7-5",
                    area: "南楼",
                    floor: "F7",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F2-1-a",
                    DDC: "DDC-F2-4",
                    area: "北楼",
                    floor: "F2",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F2-1-b",
                    DDC: "DDC-F2-6",
                    area: "北楼",
                    floor: "F2",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F5-1",
                    DDC: "DDC-F5-4",
                    area: "北楼",
                    floor: "F5",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-F7-1",
                    DDC: "DDC-F7-6",
                    area: "北楼",
                    floor: "F7",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
                {
                    plant_no: "AHU-B3-7a",
                    DDC: "",
                    area: "地下",
                    floor: "B3",
                    control: "",
                    run_status: "",
                    failure_warning: "",
                    manual_automatic: "",
                    antifreeze_alarm: "",
                    setting_temperature: "",
                    setting_humidity: "",
                    supply_air_temperature: "",
                    blasting_humidity: "",
                    action: "操作",
                },
            ],
            select: 0,
            perPage: 16,
            currentPage: 1,
        });

        return {
            ...toRefs(event),
        };
    },
};
</script>

<style lang="scss" scoped></style>
